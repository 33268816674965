import * as React from "react";
import Seo from "../components/Seo";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Button from "../components/ButtonRed";
import { Container, Row, Col } from "react-bootstrap";
import VerticalPixelImage from "../components/VerticalPixelImage"
import BlueBanner from "../components/BlueBanner";
import MoreInfos from "../components/MoreInfos";
import TogetherSection from "../components/TogetherSection";
import GradientSection from "../components/GradientSection";
import ImageP from "../images/banner-wealth.svg";
import wealthPhoneImage from "../images/wealth-phone.svg"
import logoWealth from "../images/logo-wealth.svg"
import logoWealthMobile from "../images/logo-wealth-mobile.svg"
import iphonesWealthImage from "../images/iphones-wealth.png"
import alternativeIcon from "../images/alternative-icon.svg"
import allianzIcon from "../images/allianz-icon.svg"
import columbusIcon from "../images/columbus-icon.svg"
import oneProductIcon from "../images/one-product-icon.svg"
import securityIcon from "../images/security-icon.svg"
import expertsIcon from "../images/experts-icon.svg"
import liquidityIcon from "../images/liquidity-icon.svg"
import benefitsIcon from "../images/benefits-icon.svg"
import benefitsBubbleIcon from "../images/benefits-bubble-icon.svg"
import taxDeferralIcon from "../images/tax-deferral-icon.svg"
import exemptionIcon from "../images/exemption-icon.svg"
import inheritanceIcon from "../images/inheritance-icon.svg"

export default function SowosWealth() {

  return (

    <div id="sowosWealth">
      <Seo
        title="SOWOS Wealth: Invierte en automático de la mano de los expertos"
        description="Invierte con un instrumento manejado por expertos que te permite ahorrar automáticamente sin plazos forzosos ni penalidades."
        image="/sowos_wealth.png"
      />
      <Navbar />
      <BlueBanner bannerSubtitle="Te presentamos:" bannerTitle="SOWOS WEALTH" urlImage={ImageP} alt="icono-wealth" bannerDescription="Pon tu dinero en manos de los expertos y velo crecer todos los días mientras inviertes automáticamente." />
      <div className="container-about">
        <Container>
          <Row className="mt-lg-5 justify-content-end">
            <Col className="d-none d-lg-flex align-items-center" md={1}>
              <VerticalPixelImage />
            </Col>
            <Col className="d-none d-lg-flex justify-content-center" md={4}>
              <img
                className="wealth-phone"
                src={wealthPhoneImage}
                alt="Imagen de Celular con el sistema SOWOS - Wealth."
              />
            </Col>
            <Col className="pe-lg-5 mb-5" lg={6}>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <img
                  className="img-fluid mt-lg-5 d-none d-lg-flex"
                  src={logoWealth}
                  alt="Imagen del logo de Wealth - Columbus."
                />
                <img
                    className="img-fluid d-lg-none"
                    src={logoWealthMobile}
                    alt="Imagen del logo de Wealth - Columbus para móvil."
                />
              </div>
              <h1 className="fw-bolder title-about text-danger my-4 my-lg-5">¿Qué es SOWOS WEALTH?</h1>
              <p className="mr-5 description-about">
                Es un <span className="fw-bold">Seguro - Inversión </span>
                que te permite ahorrar automáticamente y acceder a
                <span className="fw-bold">
                  opciones de inversión asesoradas por Columbus de México.
                </span>
              </p>
              <p className="mb-4 mb-lg-5 important-description fw-bold">¡Sin plazos forzosos ni penalidades!</p>
              <Row>
                <Col xs={6} className="d-flex d-lg-none justify-content-center">
                  <img
                    className="wealth-phone"
                    src={wealthPhoneImage}
                    alt="Imagen de Celular con el sistema SOWOS - Wealth."
                  />
                </Col>
                <Col xs={6} lg={12}>
                  <p className="mb-4 mb-lg-5 description-blue text-primary fw-bold">¡Comienza con un monto único de apertura desde $1,000 MXN! *</p>
                  <p className="mb-0 infos-content">* Se requiere cubrir una prima única de $500 MXN + una Aportación inicial mínima de $500 MXN que se verá reflejada en tu saldo.</p>
                </Col>
              </Row>
              {/* <p className="mb-5 description-blue text-primary fw-bold">¡Comienza con un monto único de apertura desde $1,000 MXN! *</p>
              <p className="mb-0 infos-content">* Se requiere cubrir una prima única de $500 MXN + una Aportación inicial mínima de $500 MXN que se verá reflejada en tu saldo.</p> */}
            </Col>
            <Col className="d-none d-lg-flex justify-content-end align-items-center" md={1}>
              <VerticalPixelImage />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="register-container">
        <Container className="register-banner px-0 px-lg-5 py-lg-5">
          <Row className="m-0 p-0 d-lg-none">
            <Col xs={10} className="iphones-wealth-image-container d-flex d-lg-none justify-content-end mb-4 px-0">
              <img
                className="iphones-wealth-image img-fluid"
                src={iphonesWealthImage}
                alt="Imagen de dos iphones-wealth."
              />
            </Col>
            <Col className="pixel-image-container d-flex align-items-center justify-content-end pe-0" xs={2}>
              <VerticalPixelImage />
            </Col>
          </Row>
          <Row className="px-4">
            <Col className="px-0 pe-lg-3" lg={{ span: 4, offset: 1 }}>
              <h2 className="register-banner-title text-center text-lg-start fw-bold mb-4">QUEREMOS VERTE CUMPLIR TUS METAS</h2>
              <p className="register-banner-description text-center text-lg-start fw-light mb-5 mb-lg-4">Configura tus aportaciones, define tus metas financieras y deja que SOWOS te acompañe en el proceso.</p>
              <div className="mb-5">
                <Button title="ÚNETE A SOWOS" href={`${process.env.GATSBY_APP_URL}/sign-up`} />
              </div>
            </Col>
            <Col className="d-none d-lg-flex iphones-image-container px-0" lg={5}>
              <img
                className="iphones-wealth-image"
                src={iphonesWealthImage}
                alt="Imagen de dos iphones-wealth."
              />
            </Col>
            <Col className="d-none d-lg-flex justify-content-center align-items-center" lg={2}>
              <VerticalPixelImage />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="about-insurance-container">
        <Container>
          <h2 className="insurance-title mb-4 fw-bold text-center">¿Qué es un Seguro - Inversión?</h2>
          <div className="d-flex justify-content-center mb-4 mb-lg-5">
            <p className="insurance-description text-center fw-normal">Es una
              <span className="fw-bold"> forma inteligente de invertir </span>
              para personas que buscan una inversión automática con liquidez y seguridad sin tener que sacrificar rendimientos.</p>
          </div>
          <Row className="">
            <Col className="px-lg-5 mb-4 mb-lg-0" xs={12} lg={4}>
              <div className="icon-container d-flex justify-content-center mb-4 mb-lg-5">
                <img
                  className="alternative-icon"
                  src={alternativeIcon}
                  alt="Imagen de un icono de check."
                />
              </div>
              <p className="text-description text-center mb-3">Es una <span className="text-danger fw-bold">Alternativa de Inversión para tus metas de mediano y largo plazo</span><span className="fw-bold"> bajo la figura legal y los beneficios de un Seguro de Vida.</span></p>
            </Col>
            <Col className="px-lg-5 mb-5 mb-lg-0" xs={12} lg={4}>
              <div className="icon-container d-flex justify-content-center mb-4 mb-lg-5">
                <img
                  className="allianz-icon"
                  src={allianzIcon}
                  alt="Imagen de un icono de candado."
                />
              </div>
              <p className="text-description text-center mb-3">Creado en conjunto con <span className="text-danger fw-bold">Allianz de México,</span> uno de los administradores de activos más grandes del mundo.</p>
              <div className="d-flex justify-content-center">
                <a className="link-content" target="_blank" href="https://www.allianz.com.mx/quienes-somos/allianz-en-mexico.html">Sobre Allianz {'>'}</a>
              </div>
            </Col>
            <Col className="px-lg-5 mb-4 mb-lg-0" xs={12} lg={4}>
              <div className="icon-container d-flex justify-content-center mb-4 mb-lg-5">
                <img
                  className="columbus-icon"
                  src={columbusIcon}
                  alt="Imagen de un icono de dos personas dando la mano."
                />
              </div>
              <p className="text-description text-center mb-3">Tus inversiones son  <span className="text-danger fw-bold">administradas por Columbus de México;</span> uno de las principales firmas de gestión patrimonial en el país.</p>
              <div className="d-flex justify-content-center">
                <a className="link-content text-center" target="_blank" href="https://columbus.mx/">Sobre Columbus {'>'}</a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mt-2 mt-lg-5">
              <Button title="ÚNETE A SOWOS" href={`${process.env.GATSBY_APP_URL}/sign-up`} />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="sowos-wealth-decription-container">
        <Container>
          <Row className="p-lg-0 m-lg-0">
            <Col className="d-lg-none ps-0" xs={2}>
              <VerticalPixelImage />
            </Col>
            <Col className="p-0" xs={8} lg={12}>
              <h2 className="section-title text-primary fw-bold text-center mb-5 mb-lg-4">Características de SOWOS WEALTH</h2>
            </Col>
            <Col className="d-lg-none pe-0 d-flex justify-content-end" xs={2}>
              <VerticalPixelImage />
            </Col>
          </Row>
          <Row>
            <Col className="d-none d-lg-flex" lg={2}>
              <VerticalPixelImage />
            </Col>
            <Col lg={8}>
              <Row className="mb-lg-5">
                <Col className="d-flex align-items-center mb-5 mb-lg-0" xs={12} lg={6}>
                  <Row>
                    <Col className="d-flex justify-content-center" xs={3} lg={3}>
                      <img
                        className="one-product-icon"
                        src={oneProductIcon}
                        alt="Imagen que simboliza un-solo-producto."
                      />
                    </Col>
                    <Col xs={9} lg={9}>
                      <p className="features-content">Una cuenta de inversión y un seguro de vida <span className="fw-bold">en un solo producto.</span></p>
                    </Col>
                  </Row>
                </Col>
                <Col className="d-flex align-items-center mb-5 mb-lg-0" xs={12} lg={6}>
                  <Row>
                    <Col className="d-flex justify-content-center" xs={3} lg={3}>
                      <img
                        className="security-icon"
                        src={securityIcon}
                        alt="Imagen de un icono de candado."
                      />
                    </Col>
                    <Col xs={9} lg={9}>
                      <p className="features-content"><span className="fw-bold">Instrumento seguro,</span> confidencial, inembargable y con la solidez financiera de Allianz.</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex align-items-center mb-5 mb-lg-0" xs={12} lg={6}>
                  <Row>
                    <Col className="d-flex justify-content-center" xs={3} lg={3}>
                      <img
                        className="experts-icon"
                        src={expertsIcon}
                        alt="Imagen de un icono de moneda - dinero."
                      />
                    </Col>
                    <Col xs={9} lg={9}>
                      <p className="features-content"><span className="fw-bold">Acceso a expertos:</span> te da acceso a alternativas de inversión manejado por expertos.</p>
                    </Col>
                  </Row>
                </Col>
                <Col className="d-flex align-items-center" xs={12} lg={6}>
                  <Row>
                    <Col className="d-flex justify-content-center" xs={3} lg={3}>
                      <img
                        className="liquidity-icon"
                        src={liquidityIcon}
                        alt="Imagen de un icono de tarjetas de crédito."
                      />
                    </Col>
                    <Col xs={9} lg={9}>
                      <p className="features-content"><span className="fw-bold">Liquidez total:</span> Puedes hacer retiros parciales o totales semanalmente (sin costos ni comisiones).</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="d-none d-lg-flex justify-content-end" lg={2}>
              <VerticalPixelImage />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="benefits-container mx-5 mx-lg-0">
        <Container>
          <Row>
            <Col className="ps-0" lg={{ span: 5, offset: 1 }}>
              <p className="benefits-title text-danger text-center text-lg-start fw-bold mb-4 mb-lg-5">Beneficios Fiscales de un Seguro de vida - Inversión</p>
              <div className="d-none d-lg-flex">
                <img
                  className="benefits-icon"
                  src={benefitsIcon}
                  alt="Imagen de un icono que simboliza el seguro de vida."
                />
                <img
                  className="benefits-bubble-icon"
                  src={benefitsBubbleIcon}
                  alt="Imagen de un icono que simboliza las inversiones."
                />
              </div>
            </Col>
            <Col lg={{ span: 5, offset: -1 }}>
              <p className="subtitle-benefits text-center text-lg-start mb-5 mb-lg-5">Cuenta con los siguientes <span className="fw-bold">beneficios fiscales,</span> para que al final recibas más:</p>
              <Row className="mb-5 mb-lg-5">
                <Col className="pe-0 pe-lg-2" xs={2} lg={2}>
                  <img
                    className="tax-deferral-icon"
                    src={taxDeferralIcon}
                    alt="Imagen de un icono que simboliza los beneficios fiscales."
                  />
                </Col>
                <Col className="d-flex align-items-center ps-4 ps-lg-0" xs={10} lg={10}>
                  <p className="description-benefits mb-0"><span className="fw-bold">1. Diferimiento de impuestos:</span> Aplazas el pago de impuestos mientras tus recursos continúen invirtiéndose.</p>
                </Col>
              </Row>
              <Row className="mb-5 mb-lg-5">
                <Col className="pe-0 pe-lg-2" xs={2} lg={2}>
                  <img
                    className="exemption-icon"
                    src={exemptionIcon}
                    alt="Imagen de un icono que simboliza el diferimiento de impuestos."
                  />
                </Col>
                <Col className="d-flex align-items-center ps-4 ps-lg-0" xs={10} lg={10}>
                  <p className="description-benefits mb-0"><span className="fw-bold">2. Exención:</span> No pagas impuestos sobre los rendimientos de tu inversión al cumplir 60 años de edad (y al menos 5 años de antigüedad con tu póliza).</p>
                </Col>
              </Row>
              <Row className="mb-lg-5">
                <Col className="pe-0 pe-lg-2" xs={2} lg={2}>
                  <img
                    className="inheritance-icon"
                    src={inheritanceIcon}
                    alt="Imagen de un icono que simboliza la herencia."
                  />
                </Col>
                <Col className="d-flex align-items-center ps-4 ps-lg-0" xs={10} lg={10}>
                  <p className="description-benefits mb-0"><span className="fw-bold">3. Herencia:</span> En caso de fallecimiento, tus beneficiarios recibirán tu patrimonio libre de gravamen.</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <GradientSection title="Con SOWOS WEALTH tienes todo lo que necesitas" titleBold="para generar más por tu dinero​."
        descriptionBoldFirst="Contratación 100% digital,"
        descriptionFirst="hoy mismo en menos de 20 minutos.*"
        descriptionBoldFirstTwo=""
        descriptionFirstTwo=""
        descriptionSecond="Producto con"
        descriptionSecondBold="condiciones negociadas especialmente para ti."
        descriptionThird="Mínimos montos de apertura:"
        descriptionThirdBold="Comienzas desde $1,000 MXN.**"
        descriptionFourth="Adminístralo"
        descriptionFourthBold="directamente desde SOWOS,"
        descriptionFourthTwo="en la palma de tu mano."
        infoFirst="* Este proceso es llevado a cabo con Allianz, Compañía de Seguros, conforme a sus normativas y disposiciones vigentes."
        infoSecond="** Se requiere cubrir una prima única de $500 MXN + una Aportación inicial mínima de $500 MXN que se verá reflejada en tu saldo."
      />
      <MoreInfos title="¿Aún tienes dudas?"
                 descriptionFirst="Déjanos tus datos y nos pondremos en contacto contigo"
                 descriptionSecond="para compartirte información, noticias, testimoniales y"
                 descriptionThird="certificaciones directamente en tu correo."
                 errorText="Por favor ingresa un correo respectando el formato ejemplo@gmail.com"
      />
      <TogetherSection title="Juntos" />
      <Footer />
    </div>
  );

}
